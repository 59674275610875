import { daEventCenter as  daEventCenterV2, isAbcdEvent } from '@shein-aidc/basis-sa-event-center'

import controleCenter from './control-center'
import publisher from './publisher'
import subscriber from './subscriber'

import analysisReport from './analysis/report'

import eventIndex from './event/index'
import { SILogger } from '@/public/src/pages/common/monitor'

class eventCenter {
  constructor () {
    // create channel control center
    this.controlCenter = new controleCenter()

    // create publisher
    this.publisher = new publisher({ controlCenter: this.controlCenter })

    // create subscribe
    this.subscriber = new subscriber({ controlCenter: this.controlCenter })

    this.event = new eventIndex({
      controlCenter: this.controlCenter,
      publisher: this.publisher,
      subscriber: this.subscriber
    })

    // 页面曝光 code 收集,eg: { pathname: new Set() }
    this.exposeCodeMap = new Map()

    this.getExposeInstance = daEventCenterV2.getExposeInstance.bind(daEventCenterV2)
  }

  /**
     * 对外暴露绑定观察者
    */
  addSubscriber ({ modulecode = '' } = {}) {
    if (!modulecode) return
    this.subscriber.subscribe({ modulecode })
  }

  /**
     * 对外暴露 手动触发通知事件
    */
  triggerNotice ({ target = '', daIdTag = '', daId = '', bindData = null, sendType = 'user', extraData = {}, delay = 0 } = {}) {
    if (!daId) return
    // 没有传入dom
    if (daIdTag && !target) {
      const item = $(`[${daIdTag}="${daId}"]`)
      target = item && item.length > 0 && item[0]
    }
    
    if(!isAbcdEvent(daId)) {
      console.warn(`%c[eventCenter]The version of eventCenter used does not match ${daId}, you should import @shein-aidc/basis-sa-event-center, not public/src/services/eventCenter/index`, 'color: red; font-size: 16px;')
      
      SILogger.logWarn({
        tag: 'sa_track_report',
        message: 'sa report version exception',
        params: {
          id: daId
        }
      })
    }

    const notify = () => this.publisher.notify({
      target,
      daId,
      sendType,
      extraData,
      bindData,
    })

    if (delay > 0) {
      if (window.requestIdleCallback) {
        window.requestIdleCallback(notify, { timeout: delay })
      } else {
        setTimeout(notify, delay)
      }
    } else {
      notify()
    }
  }

  /**
     * 对外暴露 上报方法
    */
  reportHandler ({ sa = null } = {}) {
    analysisReport.report({ sa })
  }

  /**
     * 对外暴露 事件绑定方法
     * fix：事件冒泡被禁止的事件源
    */
  bindEvent ({ event = '', daId = '' } = {}) {
    if (!event || !daId) return
    this.event.bindEventByDaId({
      event,
      daId
    })
  }

  recordExposeCode ({ code } = {}) {
    const currentPath = location.pathname
    if (this.exposeCodeMap?.has?.(currentPath)) {
      const uniqueCodes = this.exposeCodeMap.get(currentPath)
      uniqueCodes.add(code)
    } else {
      this.exposeCodeMap?.set?.(currentPath, new Set([code]))
    }
  }
}

function getEventCenter () {
  if (typeof window === 'undefined') return {
    addSubscriber(){},
    triggerNotice(){},
    reportHandler(){},
    bindEvent(){},
    getExposeInstance(){},
    recordExposeCode(){},
  }
  if (window.daEventCenter) return window.daEventCenter

  const daEventCenter = window.daEventCenter = new eventCenter()
  return daEventCenter
}

const daEventCenter = getEventCenter()

export {
  daEventCenter
}
